import ChevronDown from '@heroicons/react/outline/ChevronDownIcon';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from '../components/atoms/link/link';
import { PageContainer } from '../components/atoms/page-container/page-container';
import { Footer } from '../components/molecules/footer/footer';
import NavBar from '../components/molecules/navbar/navbar';
import './how-it-works.scss';

const faq = [
  {
    question: 'Why do I need this?',
    answer:
      'Hummingbird is perfect for trainers who want to provide tailored workout sessions using live data. It tracks trainees’ real-time heart rate, helping you ensure trainees reach their target heart rate and recovery zones. It also helps mitigate risk by helping you know when to ease up on the workout and prevent certain trainees from overexerting themselves with dangerously high heart rates.',
  },
  {
    question: 'What devices are supported?',
    answer: (
      <>
        Sharing real-time heart rate and other metrics require an Apple Watch
        Series 3 and above. We'll be adding support for more devices soon. Have
        a specific device you want us to support? Share your suggestion with
        this{' '}
        <Link to="https://forms.office.com/r/swTccWd0sv" className="font-bold">
          form
        </Link>
        .
      </>
    ),
  },
  {
    question: 'What trainee metrics does Hummingbird currently support?',
    answer: (
      <>
        Hummingbird currently offers real-time heart rate and calorie tracking,
        and we're planning on more metrics soon. If you have a specific metric
        that you would like to see, please share your suggestion with this{' '}
        <Link to="https://forms.office.com/r/5Lrh0MNBzi" className="font-bold">
          form
        </Link>
        .
      </>
    ),
  },
  {
    question: `Do you support group sessions?`,
    answer:
      "Hummingbird only supports 1:1 sessions at the moment but support for group sessions is on the roadmap. If it's something you're keen on, we'd love to hear from you and better understand how we can make it work well for you.",
  },
  {
    question: 'How much does this cost per session/month?',
    answer: `With Hummingbird's Early Bird membership, it's free! You can use it for as any many sessions, with as many people as you would like.`,
  },
];

const HowItWorks = () => {
  const h1Style = 'font-medium text-3xl md:text-4xl mb-10';
  const h2Style = 'font-medium text-2xl md:text-3xl mb-4';
  const headshotStyle = 'rounded-full mb-4';
  const nameStyle = 'font-medium mb-1';
  const roleStyle = 'text-sm';

  return (
    <>
      <main>
        <Helmet>
          <title>Hummingbird - How It Works</title>
          <meta name="description" content="How Hummingbird works." />
        </Helmet>

        <NavBar />

        <PageContainer>
          <h1 className={h1Style}>How It Works</h1>

          <div className="embedtool mb-16">
            <iframe
              src="https://www.youtube.com/embed/HFZvCW7Nor0"
              frameBorder="0"
              allowFullScreen
            />
          </div>

          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12 lg:col-span-4 mb-8">
              <h2 className={h2Style}>Frequently Asked Questions</h2>
              <p className="text-lg md:text-xl text-gray-600">
                Can't find the answer you're looking for? Just send us an{' '}
                <Link to="mailto:support@hummingbird.fit" className="font-bold">
                  email
                </Link>{' '}
                and we'd be happy to answer it for you!
              </p>
            </div>

            <div className="col-span-12 lg:col-span-8">
              {faq.map(({ question, answer }) => {
                return (
                  <div className="mb-8">
                    <h3 className="text-lg font-medium mb-2">{question}</h3>
                    <p>{answer}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </PageContainer>
      </main>

      <Footer />
    </>
  );
};

export default HowItWorks;
